.card {
    --cui-card-bg: ;
    border: 0;
}

.cardNotSelected {
    opacity: 1;
    filter: brightness(70%);
}

.auto-played-light {
    filter: drop-shadow(5px 5px 5px rgb(255, 61, 61));
}

.auto-played-dark {
    filter: drop-shadow(5px 5px 5px rgb(249, 255, 125));
}

.highlight-player-dark {
    filter: drop-shadow(5px 5px 5px rgb(249, 255, 125)) brightness(100%) contrast(100%);
}

.highlight-player-light {
    filter: drop-shadow(5px 5px 5px rgb(255, 61, 61)) brightness(100%) contrast(100%);
}

.dull-player {
    filter: grayscale(25%) brightness(75%) contrast(75%);
    opacity: 0.7;
}

.my-cards {
    border-style: solid;
    border-width: 5px;
    border-radius: 10px;
    padding: 2px;
    background-size: contain;
}
.overlay-chip {
    margin-top: 5rem;
}

.overlay-suit-chip {
    margin-top: 1.5rem;
}

.overlay-dealer-chip {
    margin-top: 1.5rem;
}

.score-text {
    width: 4em;
    display: inline-block;
    border-radius: 16%;
}

.player-name-container {
    padding-top: 0.2em !important;
    padding-bottom: 0.2em !important;
}

.player-score-container {
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;
}

.no-shadow {
    box-shadow: none !important;
}

.thumbnail-chips {
    max-width: 70px;
    max-height: 70px;
}

.thumbnail-suit {
    max-width: 45px;
    max-height: 60px;
}

.card-transparent {
    background-color: transparent;
}

.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.card-area {
    padding: 0.5rem;
    font-weight: bold;
    text-align: center;
}

.card-img-overlay {
    padding-left: 0.2rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-right: 0.2rem;
}

.image-team1-filter {
    border: solid 4px red;
}

.image-team2-filter {
    border: solid 4px yellow;
}

.image-team3-filter {
    border: solid 4px blue;
}

.clickable:hover {
    cursor: pointer;
}

.thumbnail-size {
    max-width: 100px;
    max-height: 153px;
}

.thumbnail-size-small {
    max-width: 70px;
    max-height: 100px;
}

.thumbnail-size-extra-small {
    max-width: 45px;
    max-height: 45px;
}

.leaderboard-button {
    padding: 0.1em;
}

.called-modal,
.called-card-group {
    padding: 0;
    margin: 0;
}

.game-heading {
    padding-top: 0.5em;
    padding-bottom: 0.25em;
}

.headerArea {
    min-height: 90px;
}

.gameModalBody {
    padding: 0;
}

.leaderboardTable {
    padding: 0;
    margin: 0;
}

.gameModalCardGroup {
    padding: 0;
    margin: 0;
}

.left-padding {
    padding-left: 5px;
}

@media (min-width: 576px) {

    .modal-dialog {
        max-width: 540px;
    }
}

@media (max-width: 420px) {

    .player-score-container {
        padding: 2px 0px 2px 0px !important;
        
    }

    .player-name-container {
        display: none !important;
    }

    .button-area {
        padding: 10px 0px 10px 0px !important;
    }

    .score-text {
        width: 2em;
        display: inline-block;
    }

    .card-img-overlay {
        padding-left: 0;
        top: 1rem;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
    }
}
