.overflow-hidden {
    overflow: hidden;
}

.game-wrap {
    min-width: 650px;
    max-width: 800px;
    overflow: overlay;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.game-container {
    padding: 10px 10px 10px 10px;
}

.player-avatar {
    // vertical-align: middle;
    width: 75px;
    height: 75px;
    border-radius: 25%;
}


.avatar {
    vertical-align: middle;
    width: 75px;
    height: 75px;
    border-radius: 50%;
}

.avatar-large {
    vertical-align: middle;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.transparent {
    opacity: 0.6;
}

.diplay_image_size {
    max-width: 1100px;
    max-height: 1024px;
}

@media only screen and (max-width: 1324px) {

    .diplay_image_size {
        max-width: 1100px;
        max-height: 1068px;
    }
}

@media only screen and (max-width: 1224px) {

    .diplay_image_size {
        max-width: 1020px;
        max-height: 868px;
    }
}

@media only screen and (max-width: 1124px) {

    .diplay_image_size {
        max-width: 920px;
        max-height: 768px;
    }
}

@media only screen and (max-width: 1024px) {

    .diplay_image_size {
        max-width: 800px;
        max-height: 668px;
    }
}

// Resposiveness for Mobile view.
@media only screen and (max-width: 925px) {

    .diplay_image_size {
        max-width: 850px;
    }

    .tile_wrap {
        padding: 0 20px 0 20px;
    }
}

@media only screen and (max-width: 900px) {

    .diplay_image_size {
        max-width: 790px;
        max-height: 411px;
    }
}

@media only screen and (max-width: 823px) {

    .diplay_image_size {
        max-width: 760px;
        max-height: 411px;
    }
}

@media only screen and (max-width: 812px) {

    .diplay_image_size {
        max-width: 720px;
        max-height: 375px;
    }
}

@media only screen and (max-width: 768px) {
    .game-wrap {
        max-width: 750px;
    }

    .diplay_image_size {
        max-width: 660px;
        max-height: 1024px;
    }
}

@media only screen and (max-width: 736px) {
    .game-wrap {
        max-width: 710px;
    }

    .diplay_image_size {
        max-width: 520px;
        max-height: 414px;
    }
}

@media only screen and (max-width: 734px) {
    .game-wrap {
        max-width: 700px;
    }

    .diplay_image_size {
        max-width: 520px;
        max-height: 360px;
    }
}

@media only screen and (max-width: 667px) {
    .game-wrap {
        min-width: 600px;
        max-width: 650px;
    }

    .diplay_image_size {
        max-width: 530px;
        max-height: 375px;
    }
}

@media only screen and (max-width: 570px) {
    .game-container {
        padding: 0;
    }

    .game-wrap {
        min-width: 550px;
        max-width: 560px;
    }

    .diplay_image_size {
        max-width: 460px;
    }

}

@media only screen and (max-width: 420px) {

     .card-root {
         padding: 10px 0px 10px 0px !important;
     }

    .player-avatar {
        // vertical-align: middle;
        width: 55px;
        height: 55px;
        border-radius: 25%;
    }
    

    .game-wrap {
        min-width: 390px;
        max-width: 410px;
    }

    .diplay_image_size {
        max-width: 320px;
    }

}

@media only screen and (max-width: 385px) {
    .game-wrap {
        min-width: 370px;
        max-width: 375px;
    }

    .diplay_image_size {
        max-width: 280px;
    }
}

@media only screen and (max-width: 360px) {
    .game-wrap {
        min-width: 300px;
        max-width: 350px;
    }

    .diplay_image_size {
        max-width: 260px;
    }
}

@media only screen and (max-width: 320px) {
    .game-wrap {
        max-width: 310px;
    }

    .diplay_image_size {
        max-width: 220px;
    }
}

@media (max-width: 277px) {
    .app {
        position: absolute;
    }
}
