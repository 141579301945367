// If you want to override variables do it here
@import "variables";

// Import styles
@import "@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "custom";

// Styles from Component
// Enable Component Styles here

@import "pages/Home/home";
@import "components/Header/header";
@import "pages/Game/game";
